import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { getEnvironment, isProduction, isTest } from '../env';

const DATADOG_CONFIG = {
  clientToken: process.env.DATADOG_CLIENT_TOKEN,
  site: 'ap1.datadoghq.com',
  service: 'htm-guest-portal',
  env: getEnvironment(),
  version: process.env.VERSION,
};

export function startDatadogRum() {
  if (isProduction() || isTest()) {
    datadogRum.init({
      ...DATADOG_CONFIG,
      applicationId: process.env.DATADOG_APP_ID,
      sessionSampleRate: isProduction() ? 70 : 0,
      sessionReplaySampleRate: isProduction() ? 100 : 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    });
    datadogRum.startSessionReplayRecording();
  }
}

export function startDatadogLogs() {
  if (isProduction() || isTest()) {
    datadogLogs.init({
      ...DATADOG_CONFIG,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  }
}
